import React, { Component } from "react"
import "./style.scss"

class ErrorOnAcidSection extends Component {
  componentWillMount() {
    if (typeof window !== `undefined`) document.body.classList.add("no-footer")
  }
  componentWillUnmount() {
    if (typeof window !== `undefined`)
      document.body.classList.remove("no-footer")
  }
  render() {
    return (
      <section className="error-on-acid">
        <h1 className="error-number">404</h1>
        <p className="message">
          Oops you're a little lost
          <span role="img" aria-label="Face With Head-Bandage">
            🤕
          </span>
        </p>
        <p className="not-found">
          The page you’re looking for doesn’t seem to be available.
        </p>
      </section>
    )
  }
}

export default ErrorOnAcidSection
