import React from "react"

import Seo from "../components/seo"
import Layout from "../components/layout"
import ErrorOnAcidSection from "../components/section_error_on_acid"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <ErrorOnAcidSection />
  </Layout>
)

export default NotFoundPage
